import { injectable } from "inversify";
import { IKeepJobsApiData } from "./IKeepJobsApiData";
import { IKeepAuthorisationServerData } from "./IKeepAuthorisationServerData";
import { IKeepGeneralApplicationData } from "./IKeepGeneralApplicationData";
import { IKeepAppliedJobsApiData } from "./IKeepAppliedJobsApiData";
import { IKeepAppliedResumeFilesApiData } from "./IKeepAppliedResumeFilesApiData";
import { IKeepScrappingGuardData } from "./IKeepScrappingGuardData";

@injectable()
export class ApplicationConfiguration 
    implements 
        IKeepJobsApiData, 
        IKeepAuthorisationServerData, 
        IKeepGeneralApplicationData, 
        IKeepAppliedJobsApiData, 
        IKeepAppliedResumeFilesApiData,
        IKeepScrappingGuardData {

    public get jobsOfferApiUrl(): string { 
        const url = process.env.REACT_APP_SEARCH_JOB_OFFERS_API;        
        return url!;
    }

    public get applicationName(): string {
        const applicationName = process.env.REACT_APP_AUTHENTICATION_SERVER_APPLICATION_NAME; 
        return applicationName!;
    }

    public get applicationUrl(): string {
        const applicationUrl = process.env.REACT_APP_WEBSITE_URL; 
        return applicationUrl!;
    }

    public get authorisationServerApiUrl(): string {
        const authorizationServerUrl = process.env.REACT_APP_AUTHENTICATION_SERVER_URL; 
        return authorizationServerUrl!;
    }

    public get appliedJobsApiUrl(): string {
        const appliedJobsApiUrl = process.env.REACT_APP_APPLIED_JOBS_API_URL; 
        return appliedJobsApiUrl!;
    }

    public get appliedResumeFilesApiUrl(): string {
        const appliedResumeFilesApiUrl = process.env.REACT_APP_APPLIED_RESUME_FILES_API_URL; 
        return appliedResumeFilesApiUrl!;
    }

    public get scrappingGuardApiUrl(): string {
        const scrappingGuardApiUrl = process.env.REACT_APP_SCRAPPING_GUARD_API_URL; 
        return scrappingGuardApiUrl!;
    }
}
