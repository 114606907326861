import React from 'react';
import { ProtectedBase, ProtectedProps } from './ProtectedBase';

interface ProtectedComponentProps extends ProtectedProps {
    alternateComponent?: React.ReactNode | null | undefined;
}

export class ProtectedComponent extends ProtectedBase<ProtectedComponentProps> {
    render() {
        const { component, alternateComponent } = this.props;
        const hasRequiredRoles = super.hasRequiredRoles();

        if (hasRequiredRoles === undefined) { 
            return <></>;
        }

        if (!hasRequiredRoles) {
            return <>{alternateComponent}</>;
        }

        return <>{component}</>;
    }
}