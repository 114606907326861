import React,{Component} from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import './Unauthorized.css';

var bnr = require('./../../images/main-slider/slide1.jpg');


class Unauthorized extends Component{
	render(){
		return(
			<div className="page-wraper">
				<Header />
				<div className="page-content">				
					<div className="dez-bnr-inr dez-bnr-inr-md overlay-black-dark" style={{backgroundImage:"url(" + bnr + ")"}}>
						<div className="container">
							<div className="dez-bnr-inr-entry align-m text-white">
								<div className="col-lg-12 col-md-12 col-sm-12 error-page text-center">
									<h2 className="dz_error text-secondry">401</h2>
									<h3>Uuups!</h3>
									<h4 className="text-danger">Brak uprawnień</h4>
									<h5 className='text-danger'>Nie masz uprawnień do przeglądania tej strony</h5>
								</div>
							</div>
						</div>
					</div>
					
				</div>	
				
				<Footer />
			</div>
		)
	}
}
export default Unauthorized;