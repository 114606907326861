import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { resolve } from 'inversify-react';
import * as User from '../../services/User';

import { ToastItem, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Cache from "../../services/Cache";
import Session from "supertokens-auth-react/recipe/session";
import { Guid } from 'typescript-guid';
import Moment from 'react-moment';

import './SaveThisApplicationInHistoryModal.css';

interface IAmAppliedResumeWithCompanyAndJobPosition {
    applicationId: Guid;
    resumeFileName: string;
    companyName: string;
    jobPositionName: string;
    dateApplied: Date;
}

export interface SaveThisApplicationInHistoryModalComponentOptions {
    show: boolean;
    jobOfferId?: number;

    onClose: () => void;
}

export class SaveThisApplicationInHistoryModalComponent extends Component<SaveThisApplicationInHistoryModalComponentOptions> {
    @resolve(User.Types.AppliedJobSaver)
    private readonly appliedJobSaver!: User.ISaveAppliedJob;

    @resolve(User.Types.AppliedResumeFilesRetriever)
    private readonly appliedResumeFilesRetriever!: User.IRetrieveAppliedResumeFiles;

    @resolve(User.Types.AppliedJobsRetriever)
    private readonly appliedJobsRetriever!: User.IRetrieveAppliedJobs;

    @resolve(User.Types.AppliedResumeFileSaver)
    private readonly appliedResumeFileSaver!: User.ISaveAppliedResumeFile;

    state = {
        showModal: false,
        jobOfferId: 0,
        onClose: () => {},
        appliedResumeFiles: [] as IAmAppliedResumeWithCompanyAndJobPosition[],
    }

    constructor(props: SaveThisApplicationInHistoryModalComponentOptions) {
        super(props);

        this.state = {
            showModal: props.show,
            jobOfferId: props.jobOfferId || 0,
            onClose: props.onClose || (() => {}),
            appliedResumeFiles: [] as IAmAppliedResumeWithCompanyAndJobPosition[],
        };

        this.onYesClick = this.onYesClick.bind(this);
        this.onNoClick = this.onNoClick.bind(this);
        this.onNewCVSelected = this.onNewCVSelected.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.notifyAboutSuccessfulApplication = this.notifyAboutSuccessfulApplication.bind(this);
        this.notifyAboutUnsuccessfulApplication = this.notifyAboutUnsuccessfulApplication.bind(this);
        this.onCloneCVSelected = this.onCloneCVSelected.bind(this);
    }

    async componentDidUpdate(prevProps: SaveThisApplicationInHistoryModalComponentOptions) {
        if (prevProps !== this.props) {
            this.setState({
                showModal: this.props.show,
                jobOfferId: this.props.jobOfferId,
            })

            if (this.props.show) {
                const resumeFiles = await this.appliedResumeFilesRetriever.retrieve();                
                const appliedJobs = await this.appliedJobsRetriever.retrieveByIds(resumeFiles.map(x => x.applicationId));

                const appliedResumeFiles = appliedJobs
                    .map(x => {
                        const resume = resumeFiles.find(y => y.applicationId.equals(x.id));
                        return {
                            applicationId: resume?.applicationId,
                            resumeFileName: resume?.fileName,
                            companyName: x.companyName,
                            jobPositionName: x.positionName,
                            dateApplied: x.dateApplied,
                        } as IAmAppliedResumeWithCompanyAndJobPosition;
                    })
                    .sort((a, b) => b.dateApplied.getTime() - a.dateApplied.getTime());

                this.setState({ appliedResumeFiles: appliedResumeFiles });
            }
        }
    }

    hideModal() {
        this.setState({ showModal: false });
        this.state.onClose();
    }

    async onYesClick() {
        try
        {
            const applicationId = Guid.create();
            await this.appliedJobSaver.save(applicationId, this.state.jobOfferId);
        }
        catch (e)
        {
            this.notifyAboutUnsuccessfulApplication();
            return;
        }

        this.notifyAboutSuccessfulApplication(); 

        this.setState({ showModal: false });
        this.state.onClose();
    }

    private notifyAboutSuccessfulApplication() {
        toast.success(
            <div>
                <span>Aplikacja zapisana w historii</span> 
                <div>
                    <a href="/user/appliedJobs" className="site-button mt-2">Zobacz historię</a>
                </div>
            </div>); //todo: translate!
    }

    private notifyAboutUnsuccessfulApplication() {
        toast.error(<div><div>Nie udało się zapisać aplikacji. Sprawdź czy się nie zapisała w historii i jeśli nie - spróbuj ponownie.</div><br/><div className='text-center'><a href="/user/appliedJobs" className="site-button mt-2">Zobacz historię</a></div></div>, { autoClose: false }); //todo: translate!
    }

    onNoClick() {
        this.setState({ showModal: false });

        this.state.onClose();
    }

    async onNewCVSelected(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files === null || event.target.files.length === 0) {
            return;
        }

        const file = event.target.files[0];
        const fileSize = file.size; 
        const maxFileSize = 15 * 1024 * 1024; // 15MB

        if (fileSize > maxFileSize) {
            toast.error("Plik jest za duży. Maksymalny rozmiar to 15MB."); //todo: translate!            
            return;
        }

        try
        {
            const applicationId = Guid.create();
            const cvSaverPromise = this.appliedResumeFileSaver.save(applicationId, file);
            const jobApplicationSaverPromise = this.appliedJobSaver.save(applicationId, this.state.jobOfferId);

            await Promise.all([cvSaverPromise, jobApplicationSaverPromise]);
        }
        catch (e)
        {
            this.notifyAboutUnsuccessfulApplication();
            return;
        }

        this.notifyAboutSuccessfulApplication();

        this.setState({ showModal: false });
        this.state.onClose();
    }

    async onCloneCVSelected(srcApplicationId: Guid) {
        try
        {
            const applicationId = Guid.create();
            const cvSaverPromise = this.appliedResumeFileSaver.saveFromOtherResume(applicationId, srcApplicationId);
            const jobApplicationSaverPromise = this.appliedJobSaver.save(applicationId, this.state.jobOfferId);

            await Promise.all([cvSaverPromise, jobApplicationSaverPromise]);
        }
        catch (e)
        {
            this.notifyAboutUnsuccessfulApplication();
            return;
        }

        this.notifyAboutSuccessfulApplication();

        this.setState({ showModal: false });
        this.state.onClose();
    }

    render() {
        return(
            <div>
                <SessionAuth doRedirection={false} requireAuth={true} key={"LoggedInUser"}>
                    <Modal show={this.state.showModal} keyboard={false} centered backdrop="static" onHide={this.onNoClick}>
                        <Modal.Header>
                            <Modal.Title>Zapisać twoją aplikację w historii?</Modal.Title> { /* //todo: fudged! translate! */ }
                        </Modal.Header>
                        <Modal.Body>
                            Zapisując aplikację, będziesz mógł ją łatwo znaleźć w historii aplikacji i przypomnieć sobie warunki na jakie aplikowałeś. Dodatkowo, damy Ci znać jak będziesz zbyt intensywnie aplikował do tej samej firmy.
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-xs-4 col-12 mb-2">
                                        <Button variant="primary" onClick={this.onNoClick} className="w-100">Nie, nie zapisuj</Button>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-xs-4 col-12 mb-2">
                                        <Button variant="primary" onClick={this.onYesClick} className="w-100" >Zapisz bez CV</Button>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-xs-4 col-12 mb-2">
                                        <DropdownButton id="dropdown-basic-button" title="Zapisz z CV" className="w-100">
                                            <Dropdown.Item as="button">
                                                Dołącz CV <input type="file" id='file' name='file' onChange={(e) => {this.onNewCVSelected(e);}}/> {/* //todo: translate */}
                                            </Dropdown.Item>
                                            {
                                                this.state.appliedResumeFiles.length > 0 && <Dropdown.Item disabled>Ostatnio zapisane CV:</Dropdown.Item>
                                            }
                                            {
                                                this.state.appliedResumeFiles.map((resumeWithOtherData, index) => {
                                                    return <Dropdown.Item as="button" key={index} onClick={() => {this.onCloneCVSelected(resumeWithOtherData.applicationId);}}>{resumeWithOtherData.resumeFileName} 
                                                        <small> (
                                                            <span title={resumeWithOtherData.dateApplied.toLocaleString()}>
                                                                <Moment locale="pl" fromNow >{resumeWithOtherData.dateApplied}</Moment> 
                                                            </span>, {resumeWithOtherData.companyName}, {resumeWithOtherData.jobPositionName})
                                                        </small>
                                                    </Dropdown.Item>
                                                })
                                            }
                                        </DropdownButton>
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </SessionAuth>
                <SessionAuth doRedirection={false} requireAuth={false} key={"NotLoggedInUser"}>
                    <ShowInfoForNotLoggedUserComponent />
                </SessionAuth>
            </div>
        )
    }
}

class ShowInfoForNotLoggedUserComponent extends Component {

    state = {
        wasThisToastClosedIntentinally: false
    }

    @resolve(Cache.Types.CacheService)
    private readonly cacheService!: Cache.ICacheData;

    private readonly _toastId: string = "4a59423c-c992-432f-bf07-20d3686a4455";

    constructor(props: any) {
        super(props);
        
        toast.onChange((toastItem: ToastItem) => {
            if (toastItem.id === this._toastId && toastItem.status === "removed") {
                this.cacheService.put(`toastId_${this._toastId}`, "", 24 * 60 * 60 * 1000);
            }
        }); //todo: fudged! this is overwriting other toast on change handlers! create a toast service and use it instead
    }

    componentDidMount(): void {
        const wasThisToastClosedIntentinally = this.cacheService.get(`toastId_${this._toastId}`) !== null;
        this.setState({ wasThisToastClosedIntentinally: wasThisToastClosedIntentinally });
    }

    render() {
        return (
            <div>
                {
                    !this.state.wasThisToastClosedIntentinally &&
                        <NotLoggedToastUserComponent toastId={this._toastId} />
                }
                
            </div>
        );
    }
}

function NotLoggedToastUserComponent(props: any) {
    let sessionContext = Session.useSessionContext();

    if (sessionContext.loading) {
        return null;
    }

    if (sessionContext.doesSessionExist) {
        return null;
    }
    else {
        toast.info(<div>Czy wiesz że jako zalogowany użytkownik możesz zapisać swoją aplikację w historii i budować w ten sposób swoją historię by móc ją przeglądać i analizować?<br /><br />Dodatkowo dostępne są inne opcje takie jak aktywna analiza kiedy ostatnio wysyłałeś aplikację do tej samej firmy, abyś nie wyglądał na nachalnego oraz inne udogodnienia.</div>, { autoClose: false, toastId: props.toastId });
    }
    
    return null;
}