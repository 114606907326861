import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

import './YesNoModal.css';

export interface YesNoModalComponentOptions {
    title: string;
    body: string;

    show: boolean;

    yesButtonText: string;
    noButtonText: string;

    onYes?: () => void;
    onNo?: () => void;
}

export class YesNoModalComponent extends Component<YesNoModalComponentOptions> {
    state = {
        showModal: false,
        title: '',
        body: '',

        yesButtonText: '',
        noButtonText: '',

        onYes: () => {},
        onNo: () => {},
    }

    constructor(props: YesNoModalComponentOptions) {
        super(props);

        this.state = {
            showModal: props.show,
            title: props.title,
            body: props.body,

            yesButtonText: props.yesButtonText,
            noButtonText: props.noButtonText,

            onYes: props.onYes || (() => {}),
            onNo: props.onNo || (() => {}),
        }

        this.onYesClick = this.onYesClick.bind(this);
        this.onNoClick = this.onNoClick.bind(this);
    }

    componentDidUpdate(prevProps: YesNoModalComponentOptions) {
        if (prevProps !== this.props) {
            this.setState({
                showModal: this.props.show,
                title: this.props.title,
                body: this.props.body,
                
                yesButtonText: this.props.yesButtonText,
                noButtonText: this.props.noButtonText,

                onYes: this.props.onYes || (() => {}),
                onNo: this.props.onNo || (() => {})
            });
        }
    }

    onYesClick() {
        this.setState({ showModal: false });
        this.state.onYes();
    }

    onNoClick() {
        this.setState({ showModal: false });
        this.state.onNo();
    }

    render() {
        return (
          <div>
            <Modal show={this.state.showModal} onHide={this.onNoClick} keyboard={false} centered className='yesNoModal' backdrop>
              <Modal.Header>
                <Modal.Title>{this.state.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{this.state.body}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={this.onYesClick}>
                    {this.state.yesButtonText}
                </Button>
                <Button variant="primary" onClick={this.onNoClick}>
                    {this.state.noButtonText}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
      }
  }

  