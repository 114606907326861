import Session from "supertokens-auth-react/recipe/session";
import {Link} from 'react-router-dom';
import React from "react";

export function AuthenticationButtons() {
    let sessionContext = Session.useSessionContext();

    if (sessionContext.loading) {
        return null;
    }

    if (sessionContext.doesSessionExist) {
        return null;

    } else {
        const pathToRedirect = window.location.href.indexOf('/auth?redirectToPath=') === -1 ? window.location.href : '';
        const linkTo = pathToRedirect === '' ? '/auth' : `/auth?redirectToPath=${pathToRedirect}`;
        return <Link to={linkTo}><i className="fa fa-user"></i> Zaloguj</Link> 
    }
}