import React from 'react';

export class FetchUnathorizedInterceptor extends React.Component {
    state = {
        fetchInvocationsCount: 0
    }

    constructor(props: any) {
        super(props);
        const wnd = window as any;
        const tmpFetch = globalThis.fetch;
        if (wnd.fetchAlreadyMockedByUnauthorizedInterceptor == null) {
            globalThis.fetch = (async (url, options) => {
                this.setState({ fetchInvocationsCount: wnd.fetchInvocationsCount });

                try
                {
                    const requestPromise = tmpFetch(url, options);
                    const response = await requestPromise;

                    if (response.status === 401) {
                        window.location.href = "/unauthorized";
                    }

                    return response;
                }
                catch (e)
                {
                    throw e;
                }
            }) as typeof globalThis.fetch;
            wnd.fetchAlreadyMockedByUnauthorizedInterceptor = true;
        }
    }

    render() {
        return (
            <></>
        );
    }
}