export interface IAmScrappingGuardJobOffer {
    id: string;
    companyDetails: IAmScrappingGuardCompanyDetails;
    positionName: string;
    positionDescription: string;
    requiredAttributes: string[];
    niceToHaveAttributes: string[];
    methodologies: IAmScrappingGuardJobMethodology[];
    seniorityLevels: IAmScrappingGuardJobSeniorityLevel[];
    categories: string[];
    locations: IAmScrappingGuardJobOfferLocation[];
    wageDetails: IAmScrappingGuardJobWage[];
    jobOfferSource: string;
    dateAdded: Date;
    originalUrl: string;
    dailyTasks: string[];
}

export interface IAmScrappingGuardJobOfferLocation {
    name: string;
    remotePossibility: boolean;
}

export interface IAmScrappingGuardCompanyDetails {
    name: string;
    uri: string;
    imgUri: string;
}

export interface IAmScrappingGuardJobMethodology {
    description: string;
    values: string[];
}

export enum IAmScrappingGuardJobSeniorityLevel {
    Undefined = "Undefined",
    Intern = "Intern",
    Junior = "Junior",
    Regular = "Regular",
    Senior = "Senior",
    Expert = "Expert"
}

export interface IAmScrappingGuardJobWage {
    timeUnit: IAmScrappingGuardJobWageTimeUnit;
    currency: string;
    valueFrom: number;
    valueTo: number;
    additionalInfo: string;
}

export enum IAmScrappingGuardJobWageTimeUnit {
    Undefined = "Undefined",
    Hourly = "Hourly",
    Daily = "Daily",
    Weekly = "Weekly",
    Monthly = "Monthly",
    Yearly = "Yearly"
}

export interface IAmScrappingGuardPreview {
    source: string;
    pages: IAmScrappingGuardPreviewPage[];
}

export interface IAmScrappingGuardPreviewPage {
    oldJobOffer: IAmScrappingGuardJobOffer;
    newJobOffer: IAmScrappingGuardJobOffer;
    state: string;
}

export interface IGetScrappingGuardPreviewResults {
    get(): Promise<IAmScrappingGuardPreview[]>;
}

export interface IApproveScrappingGuardPreview {
    approve(source: string, url: string): Promise<any>;
}

let Types = {
    GetScrappingGuardPreviewResultsService: Symbol("GetScrappingGuardPreviewResultsService"),
    ApproveScrappingGuardPreviewService: Symbol("ApproveScrappingGuardPreviewService")
}

export { Types };