export function getFilenameFromContentDisposition(headers: Headers): string {
    const contentDisposition = headers.get('Content-Disposition');
    if (contentDisposition) {
        const filename = contentDisposition.split('filename=')[1];
        if (filename) {
            return filename.replace(/"/g, '');
        }
    }
    
    return '';
}