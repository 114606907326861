import React, {Component} from 'react';
import Moment from 'react-moment';
import {Link} from 'react-router-dom';
import './SearchJobListItem.css'

import {IAmJobFound, IAmJobFoundWage, IAmJobWageTimeUnit} from '../../services/JobSearch/';
import { roundNumber } from '../../extensions/mathExtensions';

function formatWage(wage: IAmJobFoundWage): string {
    if (wage === undefined || wage.wageRate === undefined) {
        return "";
    }

    const timeUnitString = formatTimeUnit(wage.timeUnit);
    const fromRounded = roundNumber(wage.wageRate.from, 2);
    const toRounded = roundNumber(wage.wageRate.to, 2);
    
    if (fromRounded === toRounded) {
        return fromRounded + " " + wage.wageRate.currency + " " + timeUnitString;
    }

    return fromRounded + " - " + toRounded+ " " + wage.wageRate.currency + " " + timeUnitString;
}

function formatTimeUnit(timeUnit: IAmJobWageTimeUnit): string {
    //todo: fudged! translation needed and this definitely could've been done better!
    switch(timeUnit) {
        case IAmJobWageTimeUnit.Daily:
            return "Dziennie";
        case IAmJobWageTimeUnit.Hourly:
            return "Godzinowo";
        case IAmJobWageTimeUnit.Monthly:
            return "Miesięcznie";
        case IAmJobWageTimeUnit.Weekly:
            return "Tygodniowo";
        case IAmJobWageTimeUnit.Yearly:
            return "Rocznie";
        default:
            return "";
    }
}

class SearchJobListItem extends Component<IAmJobFound>{
    imageRef: React.RefObject<HTMLImageElement>;
    imageColumnRef: React.RefObject<HTMLDivElement>;

    constructor(props: IAmJobFound) {
        super(props);

        this.imageRef = React.createRef();
        this.imageColumnRef = React.createRef();

        this.updateImageMaxWidth = this.updateImageMaxWidth.bind(this);
    }

    updateImageMaxWidth(): void {
        this.imageRef.current!.style.maxWidth = (this.imageColumnRef.current!.offsetWidth * 2 / 3) + "px";
    }

    componentDidMount(): void {
        window.addEventListener('resize', this.updateImageMaxWidth);
        this.updateImageMaxWidth();
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.updateImageMaxWidth);
    }

    render() {
        const companyImageAvailable = this.props.companyLogoUrl !== undefined && this.props.companyLogoUrl !== "" && this.props.companyLogoUrl !== null && this.props.companyLogoUrl !== "about:blank";

        return <Link to={"/job/" + this.props.hashCode} target='_blank' rel="noreferrer noopener">
            <div className="post-bx" >
                <div className="container">
                    <div className="row">
                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2" ref={this.imageColumnRef}>
                            <img src={companyImageAvailable ? this.props.companyLogoUrl : require('./../../images/company/no_company_logo.png')} alt={this.props.companyName?.trim()} ref={this.imageRef} title={this.props.companyName?.trim()}/>
                        </div>
                        <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 p-r0">
                            <span className='float-left'>
                                <h4>{this.props.positionName}</h4>
                            </span>
                            &nbsp;
                            <span className="float-right">
                                <div className="job-time">
                                    <span title={this.props.dateAdded.toLocaleString()}>
                                        <i className="fa fa-clock-o">&nbsp;</i> 
                                        <Moment locale="pl" fromNow>{this.props.dateAdded}</Moment>
                                    </span> 
                                </div>
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            &nbsp;
                        </div>
                        <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 p-r0">
                            <span title={this.props.locations?.join(", ")}>
                                <i className="fa fa-map-marker"></i> {this.props.locations?.[0] || ""}{this.props.locations?.length > 1 ? ` + ${this.props.locations.length - 1}` : ""}
                            </span>
                            &nbsp;              
                            <span title={this.props.seniorityLevels?.join(", ")}>
                                <i className="fa fa-user"></i> {this.props.seniorityLevels?.[0] || ""}{this.props.seniorityLevels?.length > 1 ? ` + ${this.props.seniorityLevels.length - 1}` : ""}
                            </span>
                            &nbsp;
                            {
                                this.props.wages?.length > 0 &&
                                <span title={this.props.wages?.map(wage => formatWage(wage)).join(", ")} className="float-right p-r0">
                                    <i className="fa fa-money"></i> {formatWage(this.props.wages?.[0] || {})}{this.props.wages?.length > 1 ? ` + ${this.props.wages.length - 1}` : ""}
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    }
}

export { SearchJobListItem };