export function convertEnumGeneric<T, U extends Record<string, string>>(
    input: T,
    targetEnum: U
  ): U[keyof U] | undefined {
    // Convert input enum value to string
    const inputValueAsString: string = input as unknown as string;
    
    // Find a matching key in the target enum based on the input value
    const matchingKey = Object.keys(targetEnum).find(key => 
      targetEnum[key] === inputValueAsString
    );
  
    // Return the matching value from the target enum, or undefined if not found
    if (matchingKey === undefined) {
        return undefined;
    }
    
    return targetEnum[matchingKey] as U[keyof U] | undefined;
  }