import React, {Component} from 'react';
import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import { resolve } from 'inversify-react';
import * as SG from '../../../services/Admin/ScrappingGuard';

import ReactJsonViewCompare from 'react-json-view-compare';
import Select from 'react-select';
import { IAmScrappingGuardPreviewPage } from '../../../services/Admin/ScrappingGuard';

import './ScrappingGuard.css';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

class ScrappingGuard extends Component{

    @resolve(SG.Types.GetScrappingGuardPreviewResultsService)
    private readonly _scrappingGuardPreviewResultsGetter!: SG.IGetScrappingGuardPreviewResults;

    @resolve(SG.Types.ApproveScrappingGuardPreviewService)
    private readonly _scrappingGuardPreviewResultsApprover!: SG.IApproveScrappingGuardPreview;

    state = {
        previewResults: [] as SG.IAmScrappingGuardPreview[],
        allSourcesAvailable: [] as { label: string; value: string; }[],
        currentPagesToValidate: [] as { label: string; value: string; }[],
        selectedSource: '' as string,
        currentOffer: null as any as IAmScrappingGuardPreviewPage,
        currentOfferLabel: () : { label: string, value: string } => { return this.state.currentOffer == null ? {} : this.formatOfferOptionLabel(this.state.currentOffer); },
        currentSourceLabel: () : { label: string, value: string } => { return this.state.selectedSource == '' ? {} : { label: this.state.selectedSource, value: this.state.selectedSource } as any; }
    }

    constructor(props: any){
        super(props);

        this.onSourceChange = this.onSourceChange.bind(this);
        this.onOfferChange = this.onOfferChange.bind(this);
        this.refresh = this.refresh.bind(this);
        this.setSource = this.setSource.bind(this);
        this.setOffer = this.setOffer.bind(this);
        this.approve = this.approve.bind(this);

        this.setStateSynchronous = this.setStateSynchronous.bind(this);
    }

    async componentDidMount(): Promise<void>{
        await this.refresh();
    }

    onSourceChange(sourceItem: { label: string; value: string; } | null): Promise<void> {
        return this.setSource(sourceItem?.value!);
    }

    onOfferChange(offerItem: { label: string; value: string; } | null): void {
        const offer = this.state.previewResults.filter(pr => pr.source === this.state.selectedSource)[0].pages.filter(p => p.newJobOffer.originalUrl === offerItem?.value)[0];

        this.setState({currentOffer: offer});
    }

    async refresh(): Promise<void> {
        const previewResults = await this._scrappingGuardPreviewResultsGetter.get();
        const allSourcesAvailable = previewResults.map(pr => { return { label: pr.source, value: pr.source }; } );

        return this.setStateSynchronous({previewResults: previewResults, allSourcesAvailable: allSourcesAvailable, currentOffer: null as any as IAmScrappingGuardPreviewPage, selectedSource: '', currentPagesToValidate: [] as { label: string; value: string; }[]});
    }

    async approve(offer: IAmScrappingGuardPreviewPage): Promise<void> {

        const currentSource = this.state.selectedSource;

        await this._scrappingGuardPreviewResultsApprover.approve(this.state.selectedSource, offer.newJobOffer.originalUrl);
        await this.refresh();
        await this.setSource(currentSource);
        this.setOffer(offer.newJobOffer.originalUrl);

        if (this.state.selectedSource !== currentSource && this.state.previewResults.filter(pr => pr.source === currentSource).length === 0) {
            toast.warning(<div>Źródło {currentSource} nie jest już dostępne. Wygląda iż wymagany jest manualny start downloadera w <a href='https://portal.azure.com'>portal.azure.com</a></div>, { autoClose: false });
        }
    }
    
    setStateSynchronous(stateUpdate: {}) : Promise<void> {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }


    setSource(source: string): Promise<void> {
        const previewResults = this.state.previewResults.filter(pr => pr.source === source);
        const pagesToValidate = previewResults
            .flatMap(pr => pr.pages)
            .map(pr => { return this.formatOfferOptionLabel(pr); } );

        const validSource = previewResults.length > 0 ? source : '';

        return this.setStateSynchronous({currentPagesToValidate: pagesToValidate, selectedSource: validSource});
    }

    formatOfferOptionLabel(offer: IAmScrappingGuardPreviewPage): any {
        return { label: `${offer.newJobOffer.originalUrl} (${offer.state})`, value: offer.newJobOffer.originalUrl }; 
    }

    setOffer(offerUrl: string): void {
        const previewResults = this.state.previewResults.filter(pr => pr.source === this.state.selectedSource);
        const offer = previewResults
            .flatMap(pr => pr.pages)
            .filter(p => p.newJobOffer.originalUrl === offerUrl)[0];

        this.setState({currentOffer: offer});
    }

    render(){
        return(
            <>
    			<Header />

				<div className="page-content bg-white">
					<div className="content-block">
						<div className="section-full bg-white p-t50 p-b20">
                            <div className="container">
                                <div className="text-center">
                                    <h2>Scrapping Guard</h2>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12">
                                        Źródło: <Select options={this.state.allSourcesAvailable} closeMenuOnSelect={true} onChange={this.onSourceChange} value={this.state.currentSourceLabel()} />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12">
                                        Oferta: <Select options={this.state.currentPagesToValidate} closeMenuOnSelect={true} onChange={this.onOfferChange} value={this.state.currentOfferLabel()} />
                                    </div>
                                {
                                    this.state.currentOffer &&
                                    <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12 zIndexZero">
                                        <ReactJsonViewCompare oldData={this.state.currentOffer.oldJobOffer} newData={this.state.currentOffer.newJobOffer} />
                                    </div>
                                }
                                {
                                    this.state.currentOffer && 
                                    this.state.currentOffer.state !== "Valid" && { /* //todo:fudged, but I don't want to copy/paste enum values from here to there and from there to here */ } &&
                                    <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12 text-right">
                                        <Button variant="success" onClick={() => this.approve(this.state.currentOffer)}>Zaakceptuj</Button>
                                    </div>                                        
                                }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    }
}

export default ScrappingGuard;