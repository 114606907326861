import { inject, injectable } from "inversify";
import { IAmAppliedJob, IRetrieveAppliedJobs } from "./abstraction";
import * as config from "../../config";
import Session from 'supertokens-auth-react/recipe/session';
import { GetJobApplicationsById, JobApplicationOutgoingDto, JobApplicationsHistoryService, OpenAPI } from "@all-it/api.users.client";
import { Guid } from "typescript-guid";

@injectable()
export class AppliedJobsRetriever implements IRetrieveAppliedJobs {
    @inject(config.Types.AppliedJobsRetrieverSettings)
    private readonly _appliedJobsApiSettings!: config.IKeepAppliedJobsApiData;

    async retrieve(): Promise<IAmAppliedJob[]> {

        const payload = await Session.getAccessTokenPayloadSecurely();
        const jwt = payload.jwt;

        OpenAPI.BASE = this._appliedJobsApiSettings.appliedJobsApiUrl;
        OpenAPI.TOKEN = jwt;

        const jobApplications = await JobApplicationsHistoryService.jobApplicationsHistoryGet();

        return jobApplications
            .map((jobApplied: JobApplicationOutgoingDto) => { 
                const retVal: IAmAppliedJob = {
                    id: Guid.parse(jobApplied.id!),
                    jobOfferId: jobApplied.jobOfferId!,
                    positionName: jobApplied.positionName!,
                    companyName: jobApplied.companyName!,
                    companyLogoUrl: jobApplied.companyLogoUrl!,
                    snapshotId: jobApplied.jobOfferSnapshotId!,
                    snapshotUrl: jobApplied.jobOfferSnapshotUrl!,
                    dateApplied: new Date(jobApplied.dateAdded!)
                };

                return retVal;
            });
    }

    async retrieveByIds(ids: Guid[]): Promise<IAmAppliedJob[]> {
        const payload = await Session.getAccessTokenPayloadSecurely();
        const jwt = payload.jwt;

        OpenAPI.BASE = this._appliedJobsApiSettings.appliedJobsApiUrl;
        OpenAPI.TOKEN = jwt;

        const body: GetJobApplicationsById = {
            jobApplicationIds: ids.map(id => id.toString())
        };

        const jobApplications = await JobApplicationsHistoryService.jobApplicationsHistoryGetByIds(body);

        return jobApplications
            .map(jobApplied => {
                const retVal: IAmAppliedJob = {
                    id: Guid.parse(jobApplied.id!),
                    jobOfferId: jobApplied.jobOfferId!,
                    positionName: jobApplied.positionName!,
                    companyName: jobApplied.companyName!,
                    companyLogoUrl: jobApplied.companyLogoUrl!,
                    snapshotId: jobApplied.jobOfferSnapshotId!,
                    snapshotUrl: jobApplied.jobOfferSnapshotUrl!,
                    dateApplied: new Date(jobApplied.dateAdded!)
                };

                return retVal;
            });
    }
}