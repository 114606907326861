import { Guid } from "typescript-guid";
import { JobOfferSource } from "@all-it/api.joboffers.client";

export interface ISearchForJobs {
    search(
        searchText: string, 
        language: IAmJobSearchLanguage, 
        jobCategories: IAmJobSearchJobCategory[],
        currency: string,
        locations: string[],
        remote: boolean,
        seniorityLevels: IAmJobSeniorityLevel[],
        wageFrom: number,
        wageTo: number,
        includeOffersWithoutSalaries: boolean,
        sources: IAmJobSource[]
    ): Promise<IAmJobFound[]>;
}

export interface IGetLocationsForJobSearch {
    get(): Promise<string[]>;
}

export interface IGetCurrenciesWithMinMaxForJobSearch {
    get(): Promise<IAmCurrencyWithMinMax[]>;
}

export interface IGetJobs {
    get(hashCode: Guid) : Promise<IAmJobDescription>;
}

let Types = {
    SearchJobsService: Symbol("SearchJobsService"),
    LocationsForJobSearchService: Symbol("LocationsForJobSearchService"),
    CurrenciesWithMinMaxForJobSerachService: Symbol("CurrenciesWithMinMaxForJobSerachService"),
    GetJobsService: Symbol("GetJobsService")
}

export { Types };

export interface IAmJobFound {
    positionName: string;
    companyName: string;
    companyLogoUrl: string;
    hashCode: string;
    wages: IAmJobFoundWage[];
    seniorityLevels: IAmJobSeniorityLevel[];
    dateAdded: Date
    locations: string[],
    sources: IAmJobSource[]
}

export interface IAmJobDescription {
    id: number;
    positionName: string;
    positionDescription: string;
    originalUri: string;
    source: JobOfferSource;
    seniorityLevels: IAmJobSeniorityLevel[];
    requiredAttributes: string[];
    niceToHaveAttributes: string[];
    dailyTasks: string[];
    methodologies: IAmJobMethodology[];
    wages: IAmJobWage[];
    company: IAmCompany;
    locations: string[];
    dateAdded: Date;
}

export interface IAmCompany {
    name: string;
    url: string;
    imgUrl: string
}

export interface IAmJobWage {
    timeUnit: IAmJobWageTimeUnit;
    currency: string;
    valueFrom: number;
    valueTo: number;
    additionalInfo: string;
}

export interface IAmJobMethodology {
    description: string;
    values: string[];
}

export interface IAmJobFoundWage {
    timeUnit: IAmJobWageTimeUnit;
    wageRate: IAmJobFoundWageRate;
    wageRateMonthly: IAmJobFoundWageRate;
}

export enum IAmJobWageTimeUnit {
    Undefined = "Undefined",
    Hourly = "Hourly", 
    Daily = "Daily",
    Weekly = "Weekly", 
    Monthly = "Monthly", 
    Yearly = "Yearly"
}

export interface IAmJobFoundWageRate {
    currency: string;
    to: number;
    from: number;
}

export enum IAmJobSeniorityLevel {
    Intern = "Intern", 
    Junior = "Junior",
    Regular = "Regular", 
    Senior = "Senior", 
    Expert = "Expert"
}

export enum IAmJobSearchLanguage {
    Unknown = "Unknown",
    Pl = "Pl",
    En = "En"
}

export enum IAmJobSearchJobCategory {
    Backend = 'Backend',
    DevOps = 'DevOps',
    BigData = 'BigData',
    ProjectManager = 'ProjectManager',
    Frontend = 'Frontend',
    Embedded = 'Embedded',
    ITSupport = 'ITSupport',
    BusinessIntelligence = 'BusinessIntelligence',
    Fullstack = 'Fullstack',
    Security = 'Security',
    ITAdministrator = 'ITAdministrator',
    BusinessAnalysis = 'BusinessAnalysis',
    Mobile = 'Mobile',
    Gaming = 'Gaming',
    Agile = 'Agile',
    Design = 'Design',
    Testing = 'Testing',
    AI = 'AI',
    ProductManagement = 'ProductManagement'
}

export interface IAmCurrencyWithMinMax {
    currency: string;
    min: number;
    max: number;
}

export enum IAmJobSource {
    NoFluffJobs = 'NoFluffJobs',
    JustJoinIt = 'JustJoinIt',
    AllIt = 'AllIt',
    BullDogJob = 'BullDogJob',
    SolidJobs = 'SolidJobs',
    Inhire = 'Inhire',
    ItPracuj = 'ItPracuj',
    TheProtocol = 'TheProtocol'
}