import { inject, injectable } from "inversify";
import { IAmJobDescription, IAmJobMethodology, IAmJobSeniorityLevel, IAmJobWage, IAmJobWageTimeUnit, IGetJobs } from "./abstraction";
import * as Cache from "../Cache";
import { OpenAPI, JobOfferGetterService } from "@all-it/api.joboffers.client";
import { Guid } from "typescript-guid";
import * as config from "../../config";
import Session from 'supertokens-auth-react/recipe/session';
import { convertEnumGeneric } from "../../extensions/enumsExtensions";

@injectable()
export class GetJobsService implements IGetJobs {
    @inject(config.Types.JobsApiSettings)
    private readonly _apiSettings!: config.IKeepJobsApiData;

    @inject(Cache.Types.CacheService)
    private readonly _cache!: Cache.ICacheData;

    async get(hashCode: Guid): Promise<IAmJobDescription> {
        const cacheKey = `JobDescription ${hashCode.toString()}`;
        const jobDescription = this._cache.get<IAmJobDescription>(cacheKey);
        if (jobDescription != null) {
            return jobDescription;
        }

        OpenAPI.BASE = this._apiSettings.jobsOfferApiUrl;

        if (await Session.doesSessionExist()) {
            const payload = await Session.getAccessTokenPayloadSecurely();
            const jwt = payload.jwt;

            OpenAPI.TOKEN = jwt;
        }

        const jobDescriptionRetrieved = await JobOfferGetterService.jobOfferGetterGet(hashCode.toString());

        const jdToReturn: IAmJobDescription = {
            id: jobDescriptionRetrieved.id!,
            company: {
                name: jobDescriptionRetrieved.companyDetails!.name!,
                url: jobDescriptionRetrieved.companyDetails!.uri!,
                imgUrl: jobDescriptionRetrieved.companyDetails!.imgUri!
            },
            methodologies: jobDescriptionRetrieved.methodologies!.map(val => {
                const methodology: IAmJobMethodology = {
                    description: val.description!,
                    values: val.values!
                };

                return methodology;
            }),
            niceToHaveAttributes: jobDescriptionRetrieved.niceToHaveAttributes!,
            dailyTasks: jobDescriptionRetrieved.dailyTasks!,
            originalUri: jobDescriptionRetrieved.originalUrl!,
            positionDescription: jobDescriptionRetrieved.positionDescription!,
            positionName: jobDescriptionRetrieved.positionName!,
            source: jobDescriptionRetrieved.jobOfferSource!,
            requiredAttributes: jobDescriptionRetrieved.requiredAttributes!,
            seniorityLevels: jobDescriptionRetrieved.seniorityLevels!.map(val =>  convertEnumGeneric(val, IAmJobSeniorityLevel)!),
            wages: jobDescriptionRetrieved.wageDetails!.map(val => {
                const timeUnit = convertEnumGeneric(val.timeUnit, IAmJobWageTimeUnit)!;
                const wage: IAmJobWage = {
                    additionalInfo: val.additionalInfo!,
                    currency: val.currency!,
                    timeUnit: timeUnit,
                    valueFrom: val.valueFrom!,
                    valueTo: val.valueTo!
                }

                return wage;
            }),
            locations: jobDescriptionRetrieved.locations!.map(val => val.city!),
            dateAdded: new Date(jobDescriptionRetrieved.dateAdded!)
        };

        this._cache.put(cacheKey, jdToReturn, 1000 * 60 * 60);
        return jdToReturn;
    }

}