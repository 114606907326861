import React, {Component} from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import * as User from '../../services/User';
import { resolve } from 'inversify-react';
import { Guid } from 'typescript-guid';
import { Button } from 'react-bootstrap';
import Moment from 'react-moment';

import './AppliedJobs.css';
import { batch } from '../../extensions/arrayExtensions';
import PaginationExtended from '../Element/Pagination';
import Select from 'react-select';
import { YesNoModalComponent } from '../Element/YesNoModal';

const maxItemsPerPage = 20; //todo: fudged! this should be taken from some config, not hardcoded!

enum SortByField {
	DateAddedFromTheNewest = "dateAddedFromTheNewest",
	DateAddedFromTheOldest = "dateAddedFromTheOldest",
	CompanyNameAscending = "companyNameAscending",
	CompanyNameDescending = "companyNameDescending",
	PositionNameAscending = "positionNameAscending",
	PositionNameDescending = "positionNameDescending",
}

interface IAppliedJobsState {
	id: Guid;
    jobOfferId: number;
    positionName: string;
    companyName: string;
    companyLogoUrl: string;
    snapshotId: number;
    snapshotUrl: string;
    dateApplied: Date;
	isCvAttached: boolean;
}

interface IKeepTheState {
	currentModel: IAppliedJobsState[];
	allModelData: IAppliedJobsState[];
	allOrderByFields: { label: string; value: string; }[];
	currentOrderBy: { label: string; value: string };
	showConfirmAppRemovalModal: boolean;
	appliedJobSelectedToRemove: IAppliedJobsState;
}

class AppliedJobs extends Component<any, IKeepTheState>{
	@resolve(User.Types.AppliedJobsRetriever)
	private readonly _appliedJobsRetriever!: User.IRetrieveAppliedJobs;

	@resolve(User.Types.AppliedJobsRemover)
	private readonly _appliedJobsRemover!: User.IRemoveAppliedJob;

	@resolve(User.Types.AppliedResumeFilesRetriever)
	private readonly _appliedResumeFilesRetriever!: User.IRetrieveAppliedResumeFiles;

	@resolve(User.Types.AppliedResumeFileRemover)
	private readonly _appliedResumeFilesRemover!: User.IRemoveAppliedResumeFile;

	constructor(props: any) {
		super(props);

		const firstSelectedOrderByField = {label: "od najnowszego", value: SortByField.DateAddedFromTheNewest};

		this.state = {
			currentModel: [] as IAppliedJobsState[],
			allModelData: [] as IAppliedJobsState[],
			allOrderByFields: [
				firstSelectedOrderByField,
				{label: "od najstarszego", value: SortByField.DateAddedFromTheOldest},
				{label: "nazwa firmy rosnąco", value: SortByField.CompanyNameAscending},
				{label: "nazwa firmy malejąco", value: SortByField.CompanyNameDescending},
				{label: "nazwa stanowiska rosnąco", value: SortByField.PositionNameAscending},
				{label: "nazwa stanowiska malejąco", value: SortByField.PositionNameDescending},
			] as { label: string; value: string; }[],
			currentOrderBy: firstSelectedOrderByField,
			showConfirmAppRemovalModal: false,
			appliedJobSelectedToRemove: {} as IAppliedJobsState,
		}

		this.onChangePage = this.onChangePage.bind(this);
		this.sortJobApplications = this.sortJobApplications.bind(this);
		this.onOrderByFieldChange = this.onOrderByFieldChange.bind(this);
		this.showConfirmAppRemovalModal = this.showConfirmAppRemovalModal.bind(this);
		this.handleConfirmAppRemovalNo = this.handleConfirmAppRemovalNo.bind(this);
		this.handleConfirmAppRemovalYes = this.handleConfirmAppRemovalYes.bind(this);
		this.hideConfirmAppRemovalModal = this.hideConfirmAppRemovalModal.bind(this);
		this.rebuildModelData = this.rebuildModelData.bind(this);
		this.onShowResume = this.onShowResume.bind(this);
		this.retrieveHistory = this.retrieveHistory.bind(this);
	}

	async componentDidMount(): Promise<void> {
		await this.retrieveHistory();
	}

	async retrieveHistory(): Promise<void>{
		const appliedJobs = await this._appliedJobsRetriever.retrieve();
		const attachedResumes = await this._appliedResumeFilesRetriever.retrieve();

		const mappedAppliedJobs = appliedJobs.map((item) => {
			return {
				id: item.id,
				jobOfferId: item.jobOfferId,
				positionName: item.positionName,
				companyName: item.companyName,
				companyLogoUrl: item.companyLogoUrl,
				snapshotId: item.snapshotId,
				snapshotUrl: item.snapshotUrl,
				dateApplied: item.dateApplied,
				isCvAttached: attachedResumes.find((x) => x.applicationId.equals(item.id)) !== undefined,
			}
		});

		this.rebuildModelData(mappedAppliedJobs, this.state.currentOrderBy.value);
	}

	rebuildModelData(allModelData: IAppliedJobsState[], currentOrderBy: string) {
		const sorted = this.sortJobApplications(allModelData, currentOrderBy);
		const batchedModelData = batch(sorted, maxItemsPerPage); //todo: fudged! this should be taken from settings not hardcoded!
		const currentModel = batchedModelData?.[0] || [];

		this.setState({allModelData: allModelData, currentModel: currentModel});
	}

	onOrderByFieldChange(orderByField: {label: string, value: string} | null) {
		this.rebuildModelData(this.state.allModelData, orderByField!.value);
		const currentModel = this.state.currentModel;
		this.onChangePage(currentModel);
	}

	onChangePage(pageOfItems: IAppliedJobsState[]) {
		this.setState({currentModel: pageOfItems});
	}

	async onShowResume(applicationId: Guid): Promise<void> {
		const file = await this._appliedResumeFilesRetriever.downloadResume(applicationId);
		const fileUrl = URL.createObjectURL(file);
		window.open(fileUrl);
	}

	showConfirmAppRemovalModal(appliedJobItem: IAppliedJobsState) {
		this.setState({showConfirmAppRemovalModal: true, appliedJobSelectedToRemove: appliedJobItem});
	}

	hideConfirmAppRemovalModal() {
		this.setState({showConfirmAppRemovalModal: false, appliedJobSelectedToRemove: {} as IAppliedJobsState});
	}

	handleConfirmAppRemovalNo() {
		this.hideConfirmAppRemovalModal();
	}

	async handleConfirmAppRemovalYes() {
		this.hideConfirmAppRemovalModal();

		const applicationId = this.state.appliedJobSelectedToRemove.id;
		if (this.state.appliedJobSelectedToRemove.isCvAttached)
		{
			await this._appliedResumeFilesRemover.remove(applicationId);
		}

		await this._appliedJobsRemover.remove(applicationId);
		await this.retrieveHistory();
	};

	sortJobApplications(incomingJobApplications: IAppliedJobsState[], sortBy: string): IAppliedJobsState[] {
		return [...incomingJobApplications].sort((a, b) => {
			switch (sortBy) {
				case SortByField.DateAddedFromTheNewest:
					return b.dateApplied.getTime() - a.dateApplied.getTime();
				case SortByField.DateAddedFromTheOldest:
					return a.dateApplied.getTime() - b.dateApplied.getTime();
				case SortByField.CompanyNameAscending:
					return a.companyName.localeCompare(b.companyName);
				case SortByField.CompanyNameDescending:
					return b.companyName.localeCompare(a.companyName);
				case SortByField.PositionNameAscending:
					return a.positionName.localeCompare(b.positionName);
				case SortByField.PositionNameDescending:
					return b.positionName.localeCompare(a.positionName);
				default:
					return 0;
			}
		});
	}

	render(){
		const currentModel = this.state.currentModel;
		const currentModelToRender = currentModel.length > 0;
		const isInInitialState = this.state.allModelData == null;

		return(
			<>
			<Header />
				<div className="page-content bg-white">
					
					<div className="content-block">
						
						<div className="section-full bg-white p-t50 p-b20">
							{
								currentModelToRender &&
								<div className="container">
									<div className="row justify-content-center">
									<div className="col-xl-9 col-lg-8 m-b30 browse-job">
										<div className="job-bx-title  clearfix">
											<h5 className="font-weight-700 pull-left text-uppercase">Wysłanych aplikacji: {this.state.allModelData.length}</h5>
											<div className="float-right">
												Sortuj:
												<Select options={this.state.allOrderByFields} closeMenuOnSelect={true}
													onChange={this.onOrderByFieldChange} 
													placeholder="Sortowanie po: " value={ this.state.currentOrderBy } />
											</div>
										</div>
										<ul className="post-job-bx browse-job">
											{this.state.currentModel.map((item, index)=>(
												<li key={index}>
													<div className="post-bx">
														<div className="container">
															<div className="row">
																<div className="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
																	<img src={item.companyLogoUrl} alt={item.companyName?.trim()} />
																</div>
																<div className="col-9 col-sm-9 col-md-10 col-lg-10 col-xl-10 p-r0">
																	<span className="float-left">
																		<h4>{item.positionName}</h4>
																	</span>
																	&nbsp;
																	<span className="float-right">
																		<small>
																			<span className="text-black">Aplikowano:&nbsp;</span> 
																			<span title={item.dateApplied.toLocaleString()}>
																				<Moment locale="pl" fromNow >{item.dateApplied}</Moment>&nbsp;
																			</span>

																			<Button variant="warning" className='btn-sm' onClick={() => this.showConfirmAppRemovalModal(item)}>Usuń</Button>
																		</small>
																	</span>
																</div>
															</div>
															<div className="row m-t10">
																<div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 job-time">
																	<Button href={item.snapshotUrl} target='_blank'><i className="fa fa-file-image-o" aria-hidden="true"></i> Zobacz ofertę</Button>
																</div>
																<div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 job-time">
																	{
																		item.isCvAttached && <Button className='m-l10 float-right' onClick={() => this.onShowResume(item.id) }><i className="fa fa-file-text-o" aria-hidden="true"></i> Zobacz CV</Button>
																	}
																</div>
															</div>
														</div>
													</div>
												</li>
											))}	
											
										</ul>
										<div className="d-flex justify-content-center">
											<PaginationExtended items={this.state.allModelData!} onChangePage={this.onChangePage} initialPage={1} pageSize={maxItemsPerPage} /> 
										</div>
									</div>
									</div>
								</div>
							}
							{
								!currentModelToRender && 
								!isInInitialState &&
								<div className="row">
									<div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12">
										<div className="d-flex justify-content-center">
											<h3>Brak wyników</h3> { /* todo: fudged! */  }
										</div>
									</div>
								</div>
							}
						</div>
						
					</div>
				</div>

				<YesNoModalComponent 
					body={`Czy na pewno chcesz usunąć aplikację '${this.state.appliedJobSelectedToRemove.positionName} (${this.state.appliedJobSelectedToRemove.companyName})'?`} title='Usuń aplikację' 
					show={this.state.showConfirmAppRemovalModal} onYes={this.handleConfirmAppRemovalYes} onNo={this.handleConfirmAppRemovalNo}
					noButtonText='Nie' yesButtonText='Tak, usuń tą aplikację' />
			
			<Footer />	
			</>
		)
	}
}
export default AppliedJobs; 