import { Component } from "react";
import Session from 'supertokens-auth-react/recipe/session';

export async function getUserRolesFromToken(): Promise<string[]> {
    if(!await Session.doesSessionExist()) {
        return [];
    }

    try {
        const payload = await Session.getAccessTokenPayloadSecurely();
        return payload?.role || payload?.roles || [];
    } catch (error) {
        return [];
    }
}

export enum ProtectionRoles {
    Admin = 'admin',
    User = 'user',
    Tester = 'tester'
}

export interface ProtectedProps {
    roles: ProtectionRoles[];
    rolesLogicalOperator: 'and' | 'or';
    component: React.ReactNode;
}

interface ProtectedState {
    userRoles: string[];
    hasRequiredRoles: boolean;
}

export class ProtectedBase<T extends ProtectedProps> extends Component<T, ProtectedState> {
    state: ProtectedState = {
        userRoles: undefined as any,
        hasRequiredRoles: false
    };

    async componentDidMount() {
        const roles = await getUserRolesFromToken();
        this.setState({ userRoles: roles });
    }

    protected hasRequiredRoles(): boolean | undefined {
        const { roles, rolesLogicalOperator: logicalOperator } = this.props;
        const { userRoles } = this.state;

        if (userRoles === undefined) {
            return undefined;
        }

        let hasRequiredRoles = false;
        switch (logicalOperator) {
            case 'and':
                hasRequiredRoles = roles.every(role => userRoles.findIndex(ur => ur?.toLowerCase() === role?.toLowerCase()) > -1);
                break;
            case 'or':
                hasRequiredRoles = roles.some(role => userRoles.findIndex(ur => ur?.toLowerCase() === role?.toLowerCase()) > -1);
                break;
            default:
                throw new Error(`Logical operator ${logicalOperator} is not supported.`);
        }

        return hasRequiredRoles;
    }
}