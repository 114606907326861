import React, { useState } from 'react';
import '../../plugins/bootstrap/css/bootstrap.min.css';
import './HeaderOverlay.css';


export const HeaderOverlay = function (props) {
  const [showOverlay, setShowOverlay] = useState(false);

  const close = () => {
    setShowOverlay(false);
    props.onClose();
  }

  return (
      <>
        {
          props.showOverlay && (
            <div className="overlay" onClick={() => { close(); } }>
            </div>
          )}
      </>
    );
  };