import { inject, injectable } from "inversify";
import { IAmScrappingGuardCompanyDetails, IAmScrappingGuardJobMethodology, IAmScrappingGuardJobOffer, IAmScrappingGuardJobOfferLocation, IAmScrappingGuardJobSeniorityLevel, IAmScrappingGuardJobWage, IAmScrappingGuardJobWageTimeUnit, IAmScrappingGuardPreview, IAmScrappingGuardPreviewPage, IApproveScrappingGuardPreview, IGetScrappingGuardPreviewResults } from "./abstraction";
import * as config from "../../../config";
import { Company, JobOffer, OpenAPI, ScrappingGuardPreviewsService, SnapshotPageState } from "@all-it/api.scrappingguard.client";
import Session from 'supertokens-auth-react/recipe/session';

@injectable()
export class ScrappingGuardPreviewService implements IGetScrappingGuardPreviewResults, IApproveScrappingGuardPreview {
    @inject(config.Types.ScrappingGuardSettings)
    private readonly _apiSettings!: config.IKeepScrappingGuardData;

    async get(): Promise<IAmScrappingGuardPreview[]> {
        const payload = await Session.getAccessTokenPayloadSecurely();
        const jwt = payload.jwt;

        OpenAPI.BASE = this._apiSettings.scrappingGuardApiUrl;
        OpenAPI.TOKEN = jwt;

        const previewsWaitingForApprovals = await ScrappingGuardPreviewsService.scrappingGuardPreviewsGetAwaitingPreviews();

        const previewsToReturn = previewsWaitingForApprovals.map(preview => {
            const previewToReturn: IAmScrappingGuardPreview = {
                source: preview.source!,
                pages: preview.pages!.map(page => {
                    const pageToReturn: IAmScrappingGuardPreviewPage = {
                        oldJobOffer: this.mapJobOfferToIAmScrappingGuardJobOffer(page.oldJobOffer),
                        newJobOffer: this.mapJobOfferToIAmScrappingGuardJobOffer(page.currentJobOffer!),
                        state: page.state!
                    };

                    return pageToReturn;
                })
            };

            return previewToReturn;
        });

        return previewsToReturn;
    }

    async approve(source: string, url: string): Promise<any> {
        const payload = await Session.getAccessTokenPayloadSecurely();
        const jwt = payload.jwt;

        OpenAPI.BASE = this._apiSettings.scrappingGuardApiUrl;
        OpenAPI.TOKEN = jwt;

        await ScrappingGuardPreviewsService.scrappingGuardPreviewsApprovePreview(source, url);
    }

    private mapJobOfferToIAmScrappingGuardJobOffer(jobOffer: JobOffer | undefined): IAmScrappingGuardJobOffer {
        if (jobOffer === null || jobOffer === undefined) {
            return null as any as IAmScrappingGuardJobOffer;
        }

        const retVal: IAmScrappingGuardJobOffer = {
            companyDetails: this.mapCompanyDetails(jobOffer.companyDetails!),
            dailyTasks: jobOffer.dailyTasks!,
            jobOfferSource: jobOffer.jobOfferSource!,
            methodologies: this.mapMethodologies(jobOffer),
            dateAdded: new Date(jobOffer.dateAdded!),
            originalUrl: jobOffer.originalUrl!,
            positionDescription: jobOffer.positionDescription!,
            positionName: jobOffer.positionName!,
            requiredAttributes: jobOffer.requiredAttributes!,
            seniorityLevels: jobOffer.seniorityLevels!.map(val => val as unknown as IAmScrappingGuardJobSeniorityLevel),
            categories: jobOffer.jobOfferCategories!,
            locations: this.mapLocations(jobOffer),
            niceToHaveAttributes: jobOffer.niceToHaveAttributes!,
            wageDetails: this.mapWages(jobOffer),
            id: ""
        };

        return retVal;
    }

    private mapWages(jobOffer: JobOffer): IAmScrappingGuardJobWage[] {
        return jobOffer.wageDetails?.map(val => {
            const wage: IAmScrappingGuardJobWage = {
                additionalInfo: val.additionalInfo!,
                currency: val.currency!,
                timeUnit: val.timeUnit! as unknown as IAmScrappingGuardJobWageTimeUnit,
                valueFrom: val.from!,
                valueTo: val.to!
            };

            return wage;
        }) || [];
    }

    private mapLocations(jobOffer: JobOffer): IAmScrappingGuardJobOfferLocation[] {
        return jobOffer.locations?.map(val => {
            const location: IAmScrappingGuardJobOfferLocation = {
                name: val.name!,
                remotePossibility: val.remotePossibility!
            };

            return location;
        }) || [];
    }

    private mapMethodologies(jobOffer: JobOffer): IAmScrappingGuardJobMethodology[] {
        return jobOffer.methodologies?.map(val => {
            const methodology: IAmScrappingGuardJobMethodology = {
                description: val.description!,
                values: val.values!
            };

            return methodology;
        }) || [];
    }

    private mapCompanyDetails(companyDetails: Company): IAmScrappingGuardCompanyDetails {
        if (companyDetails === null || companyDetails === undefined) {
            return null as any as IAmScrappingGuardCompanyDetails;        
        }

        const retVal: IAmScrappingGuardCompanyDetails = {
            name: companyDetails.name!,
            uri: companyDetails.url!,
            imgUri: companyDetails.originalImgUrl!
        };

        return retVal;
    }
}