import React from 'react';
import ReactDOM from 'react-dom/client';
import { createRoot } from "react-dom/client";
import App from './App';
import * as serviceWorker from './serviceWorker';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import { Provider } from "inversify-react";
import { bootstrapper } from './bootstrapper';
import { HelmetProvider, Helmet } from 'react-helmet-async';

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
}); 
const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

const helmetContext = {};

root.render(
    <Provider container={bootstrapper}>
        <I18nextProvider i18n={i18next}>
            <HelmetProvider context={helmetContext}>
                <Helmet>
                    <title>All IT Jobs</title>
                    <meta name="description" content="All IT Jobs - we have almost all IT jobs in Poland. Find your dream job in one place." />
                </Helmet>
                <App />
            </HelmetProvider>
        </I18nextProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
