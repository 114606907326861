import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

import './Pagination.css';

export interface PaginationPager {
    totalItems: number,
    currentPage: number,
    pageSize: number,
    totalPages: number,
    startPage: number,
    endPage: number,
    startIndex: number,
    endIndex: number,
    pages: number[],
    visiblePageButtonsCount: number
}

const DefaultPaginationPager : PaginationPager = {
    currentPage: 0,
    endIndex: 0,
    endPage: 0,
    pageSize: 0,
    pages: [],
    startIndex: 0,
    startPage: 0,
    totalItems: 0,
    totalPages: 0,
    visiblePageButtonsCount: 10
}

export interface PaginationProps {
    items: any[],
    initialPage: number,
    pageSize: number,
    onChangePage(pageOfItems: any[]) : void
}

interface PaginationModel {
    pager: PaginationPager,
}

class PaginationExtended extends React.Component<PaginationProps, PaginationModel> {
    constructor(props: PaginationProps) {
        super(props);

        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.calculateVisiblePageButtonsCount = this.calculateVisiblePageButtonsCount.bind(this);

        this.state = { pager: DefaultPaginationPager };
        this.state.pager.visiblePageButtonsCount = this.calculateVisiblePageButtonsCount(window.innerWidth);
    }

    componentDidMount() {
        if (this.props && this.props.items.length) {
            let pager = this.getPager(this.props.items.length, this.props.initialPage, this.props.pageSize);
            this.setState({pager: pager});
            this.setPage(this.props.initialPage);
        }

        window.addEventListener('resize', this.handleWindowResize);
    }

    componentDidUpdate(prevProps: PaginationProps) {
        if (this.props.items !== prevProps.items) {
            let pager = this.getPager(this.props.items.length, this.props.initialPage, this.props.pageSize);
            this.setState({pager: pager});
            this.setPage(this.props.initialPage);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize = () => {        
        const { pager } = this.state;
        const visiblePageButtonsCount = this.calculateVisiblePageButtonsCount(window.innerWidth);
        if (pager.visiblePageButtonsCount !== visiblePageButtonsCount) {
            pager.visiblePageButtonsCount = visiblePageButtonsCount;
            this.setState({ pager: pager });
            this.setPage(this.props.initialPage);
        }
      };

    calculateVisiblePageButtonsCount = (windowWidth: number) => {
        if (windowWidth < 540) {
          return 5; // Set a smaller visiblePageButtonsCount for small devices
        } else {
          return 10; // Set a larger visiblePageButtonsCount for larger devices
        }
      };

    setPage(page: number) {
        let { items, pageSize } = this.props;
        let pager = this.state.pager;

        if (page < 1 || page > pager.totalPages) {
            return;
        }

        pager = this.getPager(items.length, page, pageSize);
        let pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);
        this.setState({ pager: pager });
        this.props.onChangePage(pageOfItems);
    }

    getPager(totalItems: number, currentPage: number, pageSize: number): PaginationPager {
        currentPage = currentPage || 1;

        pageSize = pageSize || 10;
        let totalPages = Math.ceil(totalItems / pageSize);        
        let startPage: number, endPage: number;

        if (totalPages <= this.state.pager.visiblePageButtonsCount) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= Math.trunc(this.state.pager.visiblePageButtonsCount / 2) + 1) {
                startPage = 1;
                endPage = this.state.pager.visiblePageButtonsCount;
            } else if (currentPage + Math.trunc(this.state.pager.visiblePageButtonsCount / 2) >= totalPages) {
                startPage = totalPages - this.state.pager.visiblePageButtonsCount + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - Math.trunc(this.state.pager.visiblePageButtonsCount / 2) + (this.state.pager.visiblePageButtonsCount % 2 === 0 ? 1 : 0);
                endPage = currentPage + Math.trunc(this.state.pager.visiblePageButtonsCount / 2) - (this.state.pager.visiblePageButtonsCount % 2 === 0 ? 1 : 0);
            }
        }

        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        var pages: number[] = Array.from(Array((endPage + 1) - startPage).keys()).map((el, i) => startPage + i);

        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages,
            visiblePageButtonsCount: this.state.pager.visiblePageButtonsCount
        };
    }

    paginationElementClick(e: React.MouseEvent<HTMLElement>, pageNumber: number) {
        this.setPage(pageNumber);
        e.currentTarget.blur();
    }

    render() {
        var pager = this.state.pager;
        if (!pager.pages || pager.pages.length <= 1) {
            return null;
        }

        return (
            <Pagination>
                <Pagination.First onClick={(e) => this.paginationElementClick(e, 1)} className={pager.currentPage === 1 ? 'disabled' : ''} />
                <Pagination.Prev onClick={(e) => this.paginationElementClick(e, pager.currentPage - 1)} className={pager.currentPage === 1 ? 'disabled' : ''} />

                {
                    pager
                        .pages
                        .map((page, index) =>
                            <Pagination.Item key={index} active={pager.currentPage === page} onClick={(e) => this.paginationElementClick(e, page)} activeLabel={""}>
                                {`${page}`}
                            </Pagination.Item>
                        )
                }

                <Pagination.Next onClick={(e) => { this.paginationElementClick(e, pager.currentPage + 1)}} />
                <Pagination.Last onClick={(e) => this.paginationElementClick(e, pager.totalPages)} className={pager.currentPage === pager.totalPages ? 'disabled' : ''} />
            </Pagination>
        );
    }
}

export default PaginationExtended;