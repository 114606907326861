import { Container } from 'inversify';
import { IKeepJobsApiData, Types as ConfigTypes, IConfigureAuthorisationServer, IKeepAuthorisationServerData, IKeepGeneralApplicationData, IKeepAppliedJobsApiData, IKeepScrappingGuardData} from './config'

import { IGetCurrenciesWithMinMaxForJobSearch, IGetJobs, IGetLocationsForJobSearch, ISearchForJobs, Types as SearchForJobsTypes } from './services/JobSearch';
import { SearchJobsService } from './services/JobSearch/SearchJobsService'
import { LocationsForJobSearchService } from './services/JobSearch/LocationsForJobSearchService';
import { CurrenciesWithMinMaxForJobSearchService } from './services/JobSearch/CurrenciesWithMinMaxForJobSearchService';

import { ApplicationConfiguration } from './config/ApplicationConfiguration';
import { AuthorisationServerConfigurator } from './config/AuthorisationServerConfigurator';

import { ICacheData, Types as CacheTypes } from './services/Cache';
import { CacheService } from './services/Cache/CacheService';
import { GetJobsService } from './services/JobSearch/GetJobsService';
import { IRemoveAppliedJob, IRemoveAppliedResumeFile, IRetrieveAppliedJobs, IRetrieveAppliedResumeFiles, ISaveAppliedJob, ISaveAppliedResumeFile, Types as UserTypes } from './services/User';
import { AppliedJobsRetriever } from './services/User/AppliedJobsRetriever';
import { AppliedJobsRemover } from './services/User/AppliedJobRemover';
import { AppliedJobSaver } from './services/User/AppliedJobSaver';
import { AppliedResumeFilesRetriever } from './services/User/AppliedResumeFilesRetriever';
import { AppliedResumeFileSaver } from './services/User/AppliedResumeFileSaver';
import { AppliedResumeFilesRemover } from './services/User/AppliedResumeFilesRemover';
import { IApproveScrappingGuardPreview, IGetScrappingGuardPreviewResults, Types as ScrappingGuardTypes } from './services/Admin/ScrappingGuard';
import { ScrappingGuardPreviewService } from './services/Admin/ScrappingGuard/ScrappingGuardPreviewService';

const bootstrapper = new Container();

bootstrapper
    .bind<IConfigureAuthorisationServer>(ConfigTypes.AuthorisationServerConfigurator)
    .to(AuthorisationServerConfigurator)
    .inSingletonScope();

bootstrapper
    .bind<ISearchForJobs>(SearchForJobsTypes.SearchJobsService)
    .to(SearchJobsService)
    .inSingletonScope();

bootstrapper
    .bind<IGetCurrenciesWithMinMaxForJobSearch>(SearchForJobsTypes.CurrenciesWithMinMaxForJobSerachService)
    .to(CurrenciesWithMinMaxForJobSearchService)
    .inSingletonScope();

bootstrapper
    .bind<IGetLocationsForJobSearch>(SearchForJobsTypes.LocationsForJobSearchService)
    .to(LocationsForJobSearchService)
    .inSingletonScope();

bootstrapper
    .bind<IKeepJobsApiData>(ConfigTypes.JobsApiSettings)
    .to(ApplicationConfiguration)
    .inSingletonScope();

bootstrapper
    .bind<IKeepAuthorisationServerData>(ConfigTypes.AuthorisationServerDataSettings)
    .to(ApplicationConfiguration)
    .inSingletonScope();

bootstrapper
    .bind<IKeepGeneralApplicationData>(ConfigTypes.GeneralApplicationSettings)
    .to(ApplicationConfiguration)
    .inSingletonScope();

bootstrapper
    .bind<IGetJobs>(SearchForJobsTypes.GetJobsService)
    .to(GetJobsService)
    .inSingletonScope();

bootstrapper
    .bind<ICacheData>(CacheTypes.CacheService)
    .to(CacheService)
    .inSingletonScope();

bootstrapper
    .bind<IKeepAppliedJobsApiData>(ConfigTypes.AppliedJobsRetrieverSettings)
    .to(ApplicationConfiguration)
    .inSingletonScope();

bootstrapper
    .bind<IKeepAppliedJobsApiData>(ConfigTypes.AppliedJobsRemoverSettings)    
    .to(ApplicationConfiguration)
    .inSingletonScope();

bootstrapper
    .bind<IRetrieveAppliedJobs>(UserTypes.AppliedJobsRetriever)
    .to(AppliedJobsRetriever)
    .inSingletonScope();

bootstrapper
    .bind<IRemoveAppliedJob>(UserTypes.AppliedJobsRemover)
    .to(AppliedJobsRemover)
    .inSingletonScope();    

bootstrapper
    .bind<ISaveAppliedJob>(UserTypes.AppliedJobSaver)
    .to(AppliedJobSaver)    
    .inSingletonScope();

bootstrapper
    .bind<IKeepAppliedJobsApiData>(ConfigTypes.AppliedResumeFilesRetrieverSettings)
    .to(ApplicationConfiguration)

bootstrapper
    .bind<IRetrieveAppliedResumeFiles>(UserTypes.AppliedResumeFilesRetriever)
    .to(AppliedResumeFilesRetriever);

bootstrapper
    .bind<ISaveAppliedResumeFile>(UserTypes.AppliedResumeFileSaver)
    .to(AppliedResumeFileSaver);

bootstrapper
    .bind<IRemoveAppliedResumeFile>(UserTypes.AppliedResumeFileRemover)
    .to(AppliedResumeFilesRemover);

bootstrapper
    .bind<IKeepScrappingGuardData>(ConfigTypes.ScrappingGuardSettings)
    .to(ApplicationConfiguration);

bootstrapper
    .bind<IGetScrappingGuardPreviewResults>(ScrappingGuardTypes.GetScrappingGuardPreviewResultsService)
    .to(ScrappingGuardPreviewService);

bootstrapper
    .bind<IApproveScrappingGuardPreview>(ScrappingGuardTypes.ApproveScrappingGuardPreviewService)
    .to(ScrappingGuardPreviewService);    

export { bootstrapper };

bootstrapper.get<IConfigureAuthorisationServer>(ConfigTypes.AuthorisationServerConfigurator).Configure();