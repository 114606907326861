import React from 'react';
import { Navigate } from 'react-router-dom';
import { ProtectedBase, ProtectedProps } from './ProtectedBase';

interface ProtectedRouteProps extends ProtectedProps {
    redirectPath: string;
}

export class ProtectedRoute extends ProtectedBase<ProtectedRouteProps> {
    render() {
        const { redirectPath, component } = this.props;
        const hasRequiredRoles = super.hasRequiredRoles();

        if (hasRequiredRoles === undefined) {
            return <></>;
        }

        if (!hasRequiredRoles) {
            // Redirect to a 'not authorized' page or home, etc.
            return <Navigate to={redirectPath} />;
        }

        return <>{component}</>;
    }
}