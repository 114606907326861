
export const requestIncludeResponseHeaders = async <T>(
    method: () => Promise<T>
  ): Promise<{
    body: T;
    headers: Headers;
  }> => {
    let headers = new Headers();
    // mock the global fetch method
    const tmpFetch = globalThis.fetch;
    globalThis.fetch = (async (url, options) => {
      // run the request (do not wait for response)
      const requestPromise = tmpFetch(url, options);
      // reset mock
      globalThis.fetch = tmpFetch;
      const response = await requestPromise;
      headers = response.headers;
      return response;
    }) as typeof globalThis.fetch;

    const apiResponse = await method();

    return {
      body: apiResponse,
      headers
    };
  };

  export const requestIncludeResponseHeadersAndRawBody = async <T>(
    method: () => Promise<T>
  ): Promise<{
    body: T;
    headers: Headers;
    rawArrayBuffer: ArrayBuffer;
  }> => {
    let headers = new Headers();
    let rawArrayBuffer:ArrayBuffer = new ArrayBuffer(0);
    // mock the global fetch method
    const tmpFetch = globalThis.fetch;
    globalThis.fetch = (async (url, options) => {
      // run the request (do not wait for response)
      const requestPromise = tmpFetch(url, options);
      // reset mock
      globalThis.fetch = tmpFetch;
      const response = await requestPromise;
      headers = response.headers;
      rawArrayBuffer = await response.clone().arrayBuffer();
      return response;
    }) as typeof globalThis.fetch;

    const apiResponse = await method();

    return {
      body: apiResponse,
      headers: headers,
      rawArrayBuffer: rawArrayBuffer
    };
  }