import React, {Component} from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { SignInAndUp } from 'supertokens-auth-react/recipe/thirdpartypasswordless';

var bnr = require('./../../images/banner/bnr2.jpg');

class LoginPage extends Component{
	render(){
		return(
			<div className="page-wraper">
				<Header />
				
				<div className="page-content">
					
					<div className="dez-bnr-inr overlay-black-middle bg-pt" style={{backgroundImage: `url(${bnr})`}}>
						<div className="container">
							<div className="dez-bnr-inr-entry">
								<SignInAndUp />								
							</div>
						</div>
					</div>
					
				</div>
				<Footer />
				
			</div>	
		)
	}
} 

export default LoginPage;