import { ICacheData } from "./abstraction";
import { injectable } from "inversify";

@injectable()
export class CacheService implements ICacheData {
    get<T>(key: string): T | null {
        const itemStr = localStorage.getItem(key);

        if (!itemStr) 
        {
            return null;
        }

        const item = JSON.parse(itemStr);
        const now = new Date();

        if (now.getTime() > item.expiry) //todo: the same should happen when new version of react app is shipped so we have to store somewhere build version an compare it just as a precaution that definition changed
        {
            localStorage.removeItem(key);
            return null;
        }

        return item.value;
    }

    put<T>(key: string, value: T | null, ttl: number): void {
        const now = new Date()
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        };

        localStorage.setItem(key, JSON.stringify(item))
    }
}