import { inject, injectable } from "inversify";
import { ISaveAppliedResumeFile } from "./abstraction";
import * as config from "../../config";
import Session from 'supertokens-auth-react/recipe/session';
import { Guid } from "typescript-guid";

import { DefaultService, OpenAPI } from "../../apiClients/getItJobsResumeApiClient";

@injectable()
export class AppliedResumeFileSaver implements ISaveAppliedResumeFile {
    @inject(config.Types.AppliedResumeFilesRetrieverSettings)
    private readonly _appliedResumeFilesApiSettings!: config.IKeepAppliedResumeFilesApiData;

    async save(applicationId: Guid, file: File): Promise<void> {
        const payload = await Session.getAccessTokenPayloadSecurely();
        const jwt = payload.jwt;

        OpenAPI.BASE = this._appliedResumeFilesApiSettings.appliedResumeFilesApiUrl;
        OpenAPI.TOKEN = jwt;
        await DefaultService.postUpload(applicationId.toString(), { file: file });
    }

    async saveFromOtherResume(applicationId: Guid, srcApplicationId: Guid): Promise<void> {
        const payload = await Session.getAccessTokenPayloadSecurely();
        const jwt = payload.jwt;

        OpenAPI.BASE = this._appliedResumeFilesApiSettings.appliedResumeFilesApiUrl;
        OpenAPI.TOKEN = jwt;
        
        await DefaultService.postClone(srcApplicationId.toString(), applicationId.toString());
    }
}