import { inject, injectable } from "inversify";
import { IAmAppliedResumeFile, IRetrieveAppliedResumeFiles } from "./abstraction";
import * as config from "../../config";
import Session from 'supertokens-auth-react/recipe/session';
import { Guid } from "typescript-guid";
import { DefaultService, OpenAPI } from "../../apiClients/getItJobsResumeApiClient";
import { requestIncludeResponseHeadersAndRawBody } from "../../extensions/fetchRequestExtensions";
import { getFilenameFromContentDisposition } from "../../extensions/headersExtensions";

@injectable()
export class AppliedResumeFilesRetriever implements IRetrieveAppliedResumeFiles {
    @inject(config.Types.AppliedResumeFilesRetrieverSettings)
    private readonly _appliedResumeFilesApiSettings!: config.IKeepAppliedResumeFilesApiData;

    async retrieve(): Promise<IAmAppliedResumeFile[]> {
        const payload = await Session.getAccessTokenPayloadSecurely();
        const jwt = payload.jwt;

        OpenAPI.BASE = this._appliedResumeFilesApiSettings.appliedResumeFilesApiUrl;
        OpenAPI.TOKEN = jwt;

        const appliedResumeFiles = await DefaultService.getList();

        return appliedResumeFiles
            .map(appliedResumeFile => {
                const retVal: IAmAppliedResumeFile = {
                    applicationId: Guid.parse(appliedResumeFile.applicationId!),
                    fileName: appliedResumeFile.cvFileName!,
                    dateAdded: new Date(appliedResumeFile.dateAdded!)
                };

                return retVal;
            });
    }

    async downloadResume(applicationId: Guid): Promise<File>{
        const payload = await Session.getAccessTokenPayloadSecurely();
        const jwt = payload.jwt;

        OpenAPI.BASE = this._appliedResumeFilesApiSettings.appliedResumeFilesApiUrl;
        OpenAPI.TOKEN = jwt;

        const responseWithHeaders = await requestIncludeResponseHeadersAndRawBody(() => DefaultService.getDownload(applicationId.toString()));
        const contentType = responseWithHeaders.headers.get("content-type") || "application/octet-stream";
        const filename = getFilenameFromContentDisposition(responseWithHeaders.headers);
        
        const rawBody = responseWithHeaders.rawArrayBuffer;

        return new File([rawBody], filename, { type: contentType });
    }
}
