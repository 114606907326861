/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * @param applicationId 
     * @param formData 
     * @returns any OK
     * @throws ApiError
     */
    public static postUpload(
applicationId: string,
formData: {
file: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/upload/{applicationId}',
            path: {
                'applicationId': applicationId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param applicationId 
     * @returns binary OK
     * @throws ApiError
     */
    public static getDownload(
applicationId: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/download/{applicationId}',
            path: {
                'applicationId': applicationId,
            },
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param applicationId 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteDelete(
applicationId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/delete/{applicationId}',
            path: {
                'applicationId': applicationId,
            },
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getList(): CancelablePromise<Array<{
applicationId?: string;
cvFileName?: string;
dateAdded?: string;
}>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/list',
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param srcApplicationId 
     * @param dstApplicationId 
     * @returns any OK
     * @throws ApiError
     */
    public static postClone(
srcApplicationId: string,
dstApplicationId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/clone/{srcApplicationId}/{dstApplicationId}',
            path: {
                'srcApplicationId': srcApplicationId,
                'dstApplicationId': dstApplicationId,
            },
            errors: {
                500: `Internal Server Error`,
            },
        });
    }

}
