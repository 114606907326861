import React,{Component} from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import './MaintenanceMode.css';

var bnr = require('./../../images/main-slider/slide1.jpg');


class MaintenanceMode extends Component{
	render(){
		return(
			<div className="page-wraper">
				<Header />
				<div className="page-content">				
					<div className="dez-bnr-inr dez-bnr-inr-md overlay-black-dark" style={{backgroundImage:"url(" + bnr + ")"}}>
						<div className="container">
							<div className="dez-bnr-inr-entry align-m text-white">
								<div className="col-lg-12 col-md-12 col-sm-12 error-page text-center">
									<h4>Serwis niedostępny</h4>
									<h5>Wygląda na to, że serwis obecnie jest dostępny tylko dla wybranej grupy użytkowników.</h5>
									<h5>Jeśli nie jesteś zalogowany, to spróbuj i sprawdź czy aby jednak nie jesteś jednym z szczęśliwców z dostępem :)</h5>
								</div>
							</div>
						</div>
					</div>
					
				</div>	
				
				<Footer />
			</div>
		)
	}
}
export default MaintenanceMode;