import { Guid } from "typescript-guid";
import { IRemoveAppliedJob } from "./abstraction";
import { inject, injectable } from "inversify";
import * as config from "../../config";
import { JobApplicationsHistoryService, OpenAPI } from "@all-it/api.users.client";
import Session from 'supertokens-auth-react/recipe/session';

@injectable()
export class AppliedJobsRemover implements IRemoveAppliedJob {
    @inject(config.Types.AppliedJobsRemoverSettings)
    private readonly _appliedJobsApiSettings!: config.IKeepAppliedJobsApiData;

    async remove(id: Guid): Promise<void> {
        const payload = await Session.getAccessTokenPayloadSecurely();
        const jwt = payload.jwt;

        OpenAPI.BASE = this._appliedJobsApiSettings.appliedJobsApiUrl;
        OpenAPI.TOKEN = jwt;

        await JobApplicationsHistoryService.jobApplicationsHistoryDelete(id.toString());
    }
}