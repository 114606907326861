import { inject, injectable } from "inversify";
import { IAmCurrencyWithMinMax, IGetCurrenciesWithMinMaxForJobSearch } from "./abstraction";
import * as Cache from "../Cache";
import { OpenAPI, JobOffersCurrenciesWithMinMaxService } from "@all-it/api.joboffers.client";
import * as config from "../../config";
import Session from 'supertokens-auth-react/recipe/session';

@injectable()
export class CurrenciesWithMinMaxForJobSearchService implements IGetCurrenciesWithMinMaxForJobSearch {
    @inject(config.Types.JobsApiSettings)
    private readonly _searchApiSettings!: config.IKeepJobsApiData;

    @inject(Cache.Types.CacheService)
    private readonly _cache!: Cache.ICacheData;

    async get(): Promise<IAmCurrencyWithMinMax[]> {
        const cacheKey = "CurrenciesWithMinMaxForJobSearchDictionary";
        const currenciesCached = this._cache.get<IAmCurrencyWithMinMax[]>(cacheKey);
        if (currenciesCached != null) {
            return currenciesCached;
        }
        
        OpenAPI.BASE = this._searchApiSettings.jobsOfferApiUrl;

        if (await Session.doesSessionExist()) {
            const payload = await Session.getAccessTokenPayloadSecurely();
            const jwt = payload.jwt;

            OpenAPI.TOKEN = jwt;
        }

        const currenciesRetrieved = await JobOffersCurrenciesWithMinMaxService.jobOffersCurrenciesWithMinMaxGetAll();

        const currencies = currenciesRetrieved.map(val => {
            const retVal = Array<IAmCurrencyWithMinMax>();

            retVal.push({
                currency: val.currency!,
                max: val.max!,
                min: val.min!
            });

            const otherCurrencies = (val.otherCurrenciesWithMinAndMax || []).map(innerVal => {
                const innerCurrency: IAmCurrencyWithMinMax = {
                    currency: innerVal.currency!,
                    max: innerVal.max!,
                    min: innerVal.min!
                };

                return innerCurrency;
            });

            retVal.push(...otherCurrencies);

            return retVal;
        }).reduce((prev, curr) => [...prev, ...curr], []);

        const minMax: any = {};

        for(let i=0;i<currencies.length;i++) {
            const curr = currencies[i];

            const accumulator = minMax[curr.currency] || {};
            accumulator.min = curr.min <= (accumulator.min || curr.min) ? curr.min : accumulator.min;
            accumulator.max = curr.max >= (accumulator.max || curr.max) ? curr.max : accumulator.max;
            minMax[curr.currency] = accumulator;
        }

        const toCache = Object.keys(minMax).map(key => {
            const retVal: IAmCurrencyWithMinMax = {
                currency: key + '',
                min: minMax[key].min,
                max: minMax[key].max
            };

            return retVal;
        });

        this._cache.put(cacheKey, toCache, 1000 * 60 * 60);

        return toCache;
    }    
}