import React, {Component} from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Jobsection from '../Element/Jobsection';
import * as JobSearch from '../../services/JobSearch';
import JobSearchform from '../Element/Jobsearchform';

var bnr1 = require('./../../images/main-slider/slide1.jpg');

interface IKeepTheState {
	isLoading: boolean;
	currentModel: Array<JobSearch.IAmJobFound> | null;
	scrollToJobs?: boolean | null;

	searchText: string;
	language: JobSearch.IAmJobSearchLanguage;
	categories: JobSearch.IAmJobSearchJobCategory[];
	filterCurrency: string;
}

class Homepage2 extends Component<any, IKeepTheState>{
	public constructor(props: {} | Readonly<{}>) {
		super(props);

		this.state = {
			isLoading: true,
			currentModel: null,
			language: JobSearch.IAmJobSearchLanguage.Unknown, 
			searchText: "",
			categories: [],
			filterCurrency: "PLN", //todo: unhardcode this!,
		};

		this.onJobsRetrieved = this.onJobsRetrieved.bind(this);
		this.onSearchStarted = this.onSearchStarted.bind(this);
	}

	onJobsRetrieved(jobs: JobSearch.IAmJobFound[], filterCurrency: string): void {
		this.setState({currentModel: jobs, filterCurrency: filterCurrency});
	}

	onSearchStarted(): void {
		this.setState({currentModel: null});
	}

	render(){
		return(
			<div className="page-wraper">
				<Header />	

				<div className="page-content">
					<div className="dez-bnr-inr dez-bnr-inr-md overlay-black-dark" style={{backgroundImage:"url(" + bnr1 + ")"}}>
						<div className="container">
							<div className="dez-bnr-inr-entry align-m text-white">
								<div className=" job-search-form">
									<h2 className="text-center"><small>(prawie)</small> Wszystkie oferty w kraju.</h2>
									<h3>Sprawdź nas i znajdź ofertę dla siebie</h3>
									<JobSearchform onDataRetrieved={this.onJobsRetrieved} onSearchStarted={this.onSearchStarted}/>
								</div>
							</div>
						</div>
					</div>
					<Jobsection CurrentModel={this.state.currentModel} MaxJobsPerPage={10} CurrentCurrency={this.state.filterCurrency} />
							
				</div>
				<Footer />
			</div>	
		)
	}
}

export default Homepage2;